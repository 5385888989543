import * as React from 'react';
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
// import styled from "styled-components"
// import styled from "@emotion/styled"
import Layout from '../components/layout';
import SiteWidthWrapper from '../components/wrappers/siteWidthWrapper';
import Seo from '../components/seo';
import HeroImageA from '../components/MainHero';
// import StyledButton from "../components/button"

const About = () => (

    <Layout>
    <Seo title='About Top1 Painting' description='A family run business' />

      {/* Hero Image is found in /components/MainHero.js */}
      <HeroImageA />

    <SiteWidthWrapper>
      <h1>About Top1 Painting &amp; Reno</h1>
      <p>Top1 Painting &amp; Reno provides exceptional Interior and Exterior painting services for residential and commercial clients. Fully licensed & insured, with over 13 years experience! We welcome the opportunity to discuss any painting project leaving you confident in our ability to deliver great customer service and outstanding craftsmanship every time.Customers are our first priority,communication, respect and trust are the rules we live by. At Top1 Painting we provide high quality painting service at a fair price. we build relationships with our customers, and by doing so we get repeat and referral business achieved through customer satisfaction at each stage of any painting project by focusing on attention to detail, timeliness, respect, cleanliness and customer appreciation.</p>
      <p>We maintain a very high level of honesty,professionalism, integrity, and fairness in our relationships with our customers and employees at all times.We work throughout Vancouver and surrounding cities including but not limited to: Coquitlam, Burnaby, New Westminster, Port Coquitlam, PitMeadows, Maple Ridge, Port Moody, Surrey, Langley.</p>
    </SiteWidthWrapper>
  </Layout>
);

export default About;
